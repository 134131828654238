import { Center, Button, HStack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useAppDispatch } from '../../../../hooks';
import { signIn } from '@mcrp/web-api';
import {
  Page,
  PageBanner,
  PageBody,
  PageFooter,
  PageHeader,
} from '@marketcast/ui-kit';
import type { Location } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const LoginPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const location = useLocation() as Location<{ from: { pathname?: string } }>;
  const handleSignIn = () => {
    void dispatch(signIn(location.state?.from?.pathname));
  };

  return (
    <Page>
      <PageHeader>
        <HStack />
        <Button onClick={handleSignIn}>Sign In</Button>
      </PageHeader>
      <PageBanner />
      <PageBody>
        <Center>Brand Effect Media Manager</Center>
      </PageBody>
      <PageFooter />
    </Page>
  );
};
